import { render, staticRenderFns } from "./authorityAssignment.vue?vue&type=template&id=6548b898&scoped=true"
import script from "./authorityAssignment.vue?vue&type=script&lang=js"
export * from "./authorityAssignment.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6548b898",
  null
  
)

export default component.exports