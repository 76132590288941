<template>
  <Modal
    :value="value"
    title="权限配置"
    @input="(val) => $emit('input', val)"
    @on-visible-change="modalShow"
  >
    <Tree ref="tree" :data="authorityMenu" show-checkbox></Tree>
    <p slot="footer" style="text-align: center">
      <Button type="primary" :loading="loading" @click="submit">提交</Button>
      <Button @click="() => $emit('input', false)">取消</Button>
    </p>
  </Modal>
</template>

<script>
import CU from "@/common/util";
export default {
  name: "",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    roleId: [String, Number],
    menuType: String
  },
  data() {
    return {
      authorityMenu: [],
      loading: false,
      ids: "",
    };
  },
  methods: {
    modalShow(visible) {
      if (visible) {
        this.getAuthorityMenu();
      } else {
        this.ids = "";
        this.authorityMenu = [];
      }
    },
    getAuthorityMenu() {
      this.$post(this.$api.ROLE.ROLE_MENU, {
        roleId: this.roleId,
        menuType: this.menuType,
      }).then((res) => {
        this.authorityMenu = CU.formatTreeComponentData(
          res,
          "name",
          "childList",
          true,
          () => false
        );
      });
    },
    // selMenu(data) {
    //   this.ids = data.map((item) => item.id).join(",");
    // },
    submit() {
      this.loading = true;
      let items = this.$refs.tree.getCheckedAndIndeterminateNodes();
      let ids = "";
      for (let item of items) {
        ids += "," + item.id;
      }
      this.$post(this.$api.ROLE.SET_AUTHORITY, {
        roleId: this.roleId,
        menuType: this.menuType,
        ids: ids.substring(1),
      })
        .then(() => {
          this.$emit("config-success");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
</style>